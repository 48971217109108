exports.columns = [
  {data: 'id'},
  {data: 'name'},
  {data: 'document'},
  {data: 'contract'},
  {data: 'date'},
  {data: 'title'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}

]
exports.config = {}
