exports.columns = [
  {data: 'id'},
  {data: 'code'},
  {data: 'type_name'},
  {data: 'location'},
  {data: 'license'},
  {data: 'brand'},
  {data: 'service_card'},
  {data: 'soat_date'},
  {data: 'mechanical_revision'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
