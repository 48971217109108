exports.columns = [
  { data: 'id' }
  { data: 'headquarter' }
  { data: 'name' }
  { data: 'last_name' }
  { data: 'document' }
  { data: 'phone' }
  { data: 'mobile' }
  { data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap' }
]

exports.config = {}
