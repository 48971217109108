config =
  processing: true
  serverSide: true
  colReorder: true
  searchDelay: 800
  stateSave: true
  dom: 'lBfrtip'
  buttons: [
    'colvis'
    {
      extend: 'excel',
      exportOptions:
        columns: ':visible'
    }
    {
      extend: 'print',
      exportOptions:
        columns: ':visible'
    }
  ]
  pagingType: "full_numbers"
  language:
    stripClasses: ''
    emptyTable: 'No hay datos aquí'
    search: '<span uk-icon="icon: search"></span>'
    infoFiltered: 'Filtrado entre _MAX_ registros'
    searchPlaceholder: 'Buscar...'
    processing: '<span uk-icon-spin uk-icon="icon: refresh"></span> Cargando...'
    info: '<span>_START_ -_END_ of _TOTAL_</span>'
    lengthMenu: "Mostrar _MENU_ registros"
    infoEmpty: 'Sin datos por aquí...'
    zeroRecords: 'Sin resultados'
    paginate:
      previous: '<span uk-icon="icon: chevron-left"></span>'
      next: '<span uk-icon="icon: chevron-right"></span>'
      first: '<span uk-icon="icon: chevron-double-left"></span>'
      last: '<span uk-icon="icon: chevron-double-right"></span>'

    buttons:
      colvis: '<span>Columns</span>'
      print: '<span uk-icon="icon: print" ></span>'
      excel: '<span uk-icon="icon: download"></span>'
module.exports = config
