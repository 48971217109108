exports.columns = [
  {data: 'id'},
  {data: 'internal_code'},
  {data: 'name'},
  {data: 'location'},
  {data: 'headquarter'},
  {data: 'condition'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
